import { createApp } from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'

import Vue3Lottie from 'vue3-lottie'
import { initSentry } from '@/sentry'
import 'izitoast/dist/css/iziToast.min.css'

import { registerPlugins } from '@core/utils/plugins'
import 'sweetalert2/dist/sweetalert2.min.css'

import App from '@/App.vue'

import { cookieRef } from '@layouts/stores/config'

import { themeConfig } from '@themeConfig'

// Styles
import '@core-scss/template/index.scss'
import '@styles/styles.scss'
import { useLanguageStore } from '@stores/language'
import { checkForLocale } from '@/plugins/i18n'

// Create vue app
const app = createApp(App)

if (import.meta.env.MODE === 'production')
  initSentry(app)
registerPlugins(app)

// app.config.performance = true

const languageStore = useLanguageStore()

languageStore.$subscribe((_, state) => {
  checkForLocale(cookieRef('language', themeConfig.app.i18n.defaultLocale).value).then(() => {
    // Register plugins

    app.use(Vue3Lottie)
    app.use(VueSweetalert2)

    // Mount vue app
    app.mount('#app')
  })
})
