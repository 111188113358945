import moment from 'moment'
import { router } from '@/plugins/2.router'
import { Payment } from '@api'
import type { Account } from '@api/models/account'
import type { ExchangeKey } from '@api/models/exchange'
import type { Package } from '@api/models/package'
import { PaymentDetails } from '@api/models/payment'
import type { PaymentStoreRequest } from '@api/requests/payment'
import { useAccountsStore } from '@stores/accounts'
import { useAuthStore } from '@stores/auth'
import { LoadingState } from '@stores/store.interface'

interface Invoice {
  payment_status: string
  exchange_key_id: number
  discount_code?: string
}

interface InvoiceCompletedEvent {
  invoice: Invoice
  exchange_key_id: number
}

interface Discount {
  code: string
  percentage: number
}
export enum PaymentType {
  StartBot,
  RestartBot,
  ExtendPlan,
  IncreaseTradingPool,
}

export const usePaymentStore = defineStore('payment', () => {
  let interval: NodeJS.Timeout | null = null
  const authStore = useAuthStore()
  const state = ref(LoadingState.Initial)
  const currenciesState = ref(LoadingState.Initial)
  const accountId = ref()
  const account = ref<Account | undefined>()
  const plan = ref<Package | undefined>()
  const discount = ref<Discount | undefined>()
  const _balance = ref<number | undefined>()
  const amount = ref<number | undefined>()
  const details = ref<PaymentDetails | undefined>()
  const _currencies = ref<string[]>([])
  const currency = ref<string | undefined>()
  const showPaymentDialog = ref(false)
  const showTradingAmountDialog = ref(false)
  const paymentStep = ref(0)
  const timer = ref(20 * 60)
  const invoice = ref()
  const tradingAmount = ref<number | undefined>()
  const succeed = ref(false)
  const extension = ref(false)
  const paymentType = ref<PaymentType | undefined>()
  const discountApplied = ref(false)

  const estimate = ref({
    amount_from: amount.value ?? 0,
    currency_from: 'usd',
    currency_to: 'btc',
    estimated_amount: 0,
  })

  const subtotal = computed(() => {
    let _fee = 0
    let month = 0
    if (plan.value) {
      _fee = plan.value.fee
      month = plan.value.month
    }
    else if (account.value) {
      month = moment(account.value?.bot?.expired_at).diff(moment(), 'days') / 30
      _fee = account.value?.package?.fee ?? 0
    }
    const percentage = (_fee * month) / 100

    return (amount?.value ?? 0) * percentage
  })

  const discountAmount = computed(() => {
    if (discount.value)
      return subtotal.value * (discount.value?.percentage / 100)

    return 0
  })

  const fee = computed(() => {
    return subtotal.value - discountAmount.value
  })

  const getCurrencies = () => {
    currenciesState.value = LoadingState.Loading
    Payment.currencies().then(curr => {
      _currencies.value = curr
      currency.value = curr[0]
      currenciesState.value = LoadingState.Loaded
    })
  }

  const currencies = computed(() => {
    if (currenciesState.value === LoadingState.Initial)
      getCurrencies()

    return _currencies.value
  })

  const startBot = async (_account: Account | ExchangeKey, restart = false) => {
    const res = await Payment.validate({
      account_id: _account.id,
    })

    paymentType.value = restart ? PaymentType.RestartBot : PaymentType.StartBot
    _balance.value = res.balance
    accountId.value = _account.id
    void router.push({
      name: 'user-packages',
    })
  }

  const extendPlan = async (_account: Account | ExchangeKey) => {
    const res = await Payment.validate({
      account_id: _account.id,
    })

    paymentType.value = PaymentType.ExtendPlan

    _balance.value = res.balance
    accountId.value = _account.id
    extension.value = true
    void router.push({
      name: 'user-packages',
    })
  }

  const increaseTradingPool = async (_account: Account) => {
    if (!_account.bot)
      return

    const res = await Payment.validate({
      account_id: _account.id,
    })

    paymentType.value = PaymentType.IncreaseTradingPool
    _balance.value = res.balance
    account.value = _account
    tradingAmount.value = _account.bot.amount
    showTradingAmountDialog.value = true
  }

  const balance = computed(() => _balance.value)

  const isDiscountAllowed = computed(() => !authStore.user?.referral_id && paymentType.value === PaymentType.StartBot)

  const choosePackage = (_plan: Package) => {
    plan.value = _plan
  }

  const startPayment = () => {
    showPaymentDialog.value = true
  }

  const setDiscount = (_discount: Discount) => {
    discount.value = _discount
    discountApplied.value = true
  }

  const reset = () => {
    if (interval) {
      clearInterval(interval!)
      interval = null
    }
    showPaymentDialog.value = false
    state.value = LoadingState.Initial
    currenciesState.value = LoadingState.Initial
    accountId.value = undefined
    plan.value = undefined
    _balance.value = undefined
    amount.value = undefined
    details.value = undefined
    _currencies.value = []
    currency.value = undefined
    showTradingAmountDialog.value = false
    paymentStep.value = 0
    timer.value = 20 * 60
    invoice.value = undefined
    paymentType.value = undefined
    discount.value = undefined
    discountApplied.value = false
  }

  const startTransaction = async () => {
    state.value = LoadingState.Loading

    try {
      let storeRequest: PaymentStoreRequest = {
        account_id: accountId.value ?? account.value?.id ?? 0,
        amount: amount?.value ?? 0,
        currency: currency.value!,
        type: extension.value ? 'extend' : 'creation',
      }
      if (plan.value) {
        storeRequest = {
          ...storeRequest,
          package_id: plan.value!.id,
        }
      }
      else if (account.value) {
        storeRequest = {
          ...storeRequest,
          account_id: account.value.id,
          type: 'fund',
        }
      }
      if (discount.value)
        storeRequest.discount = discount.value.code

      const res = await Payment.store(storeRequest)

      details.value = new PaymentDetails(res)
      paymentStep.value = 1

      authStore.$echo?.listen(`.invoices.${res.invoice_id}.completed`, (event: InvoiceCompletedEvent) => {
        invoice.value = event.invoice
        paymentStep.value = 2
        if (event.invoice.payment_status === 'finished') {
          succeed.value = true

          if (event.invoice.discount_code)
            authStore.getUser()

          const accountsStore = useAccountsStore()

          accountsStore.refreshAccount(event.invoice.exchange_key_id)
          if (account.value) {
            reset()
          }
          else {
            setTimeout(() => {
              router.replace({
                name: 'user',
              }).then(() => {
                reset()
              })
            }, 5000)
          }
        }
      })

      authStore.$echo?.listen(`.invoices.${res.invoice_id}.declined`, (event: InvoiceCompletedEvent) => {
        invoice.value = event.invoice
        paymentStep.value = 2
        succeed.value = false
        if (interval) {
          clearInterval(interval)
          interval = null
          timer.value = 20 * 60
        }
        setTimeout(() => {
          paymentStep.value = 0
          invoice.value = undefined
        }, 5000)
      })

      interval = setInterval(() => {
        timer.value--
        if (timer.value <= 0)
          clearInterval(interval!)
      }, 1000)
    }
    catch (e) {
    }
    state.value = LoadingState.Loaded
  }

  const updateEstimates = async (newCurrency: string) => {
    state.value = LoadingState.Loading
    estimate.value = await Payment.estimate({
      amount: fee.value,
      to: newCurrency,
    })
    state.value = LoadingState.Loaded
  }

  const cancelPayment = async () => {
    if (details.value)
      await Payment.cancel(details.value?.invoiceId)

    paymentStep.value = 0
    estimate.value = {
      amount_from: 0,
      currency_from: 'usd',
      currency_to: 'btc',
      estimated_amount: 0,
    }
    details.value = undefined
    showPaymentDialog.value = false
    if (interval) {
      clearInterval(interval)
      interval = null
      timer.value = 20 * 60
    }
  }

  const clearDiscount = () => {
    discount.value = undefined
    discountApplied.value = false
  }

  watchEffect(() => {
    if (currency.value && fee.value && showPaymentDialog.value)
      void updateEstimates(currency.value)
  })

  return {
    startBot,
    increaseTradingPool,
    startPayment,
    startTransaction,
    cancelPayment,
    choosePackage,
    reset,
    extendPlan,
    setDiscount,
    clearDiscount,
    account,
    tradingAmount,
    details,
    accountId,
    plan,
    balance,
    amount,
    subtotal,
    discountAmount,
    fee,
    currency,
    currencies,
    currenciesState,
    showPaymentDialog,
    showTradingAmountDialog,
    estimate,
    paymentStep,
    paymentType,
    state,
    timer,
    invoice,
    succeed,
    isDiscountAllowed,
    discountApplied,
    discount,
  }
})
