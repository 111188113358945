import { loadLocaleMessages } from '@/plugins/i18n'
import type { Language } from '@api/models/language'

export const useLanguageStore = defineStore(
  'language',
  () => {
    const languages = ref<Language[]>([])
    const ready = ref(false)

    const onInit = async () => {
      languages.value = await $api<Language[]>('/api/languages.json')
      queueMicrotask(() => {
        languages.value.forEach(language => {
          queueMicrotask(async () => await loadLocaleMessages(language.code))
        })
      })
      ready.value = true
    }

    onInit()

    return {
      languages,
      ready,
    }
  },
)
