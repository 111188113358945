<script setup lang="ts">
import moment from 'moment/moment'
import { fallbackByFirst } from '../../../utils/helpers'
import { confirmedActionsCreator } from '@/utils/admin/actions'
import QuestionFormDialog from '@/views/admin/faq/questions/QuestionFormDialog.vue'
import { paginationMeta } from '@api/utils/paginationMeta'

definePage({
  meta: {
    auth: true,
    guard: 'admin',
    layout: 'admin',
  },
})

const sort = ref([{ key: 'created_at', order: 'desc' }])
const page = ref(1)
const itemsPerPage = ref(50)
const showForm = ref(false)
const question = ref()

const sortBy = ref()
const orderBy = ref()

const { data: responseData, execute: fetchQuestions, isFetching } = await useApi(createUrl('/admin/faq/questions', {
  query: {
    per_page: itemsPerPage,
    page,
    sortBy,
    orderBy,
  },
}))

const createConfirmAction = confirmedActionsCreator(fetchQuestions)
const categoriesData = computed(() => responseData.value?.data)
const total = computed(() => responseData.value?.total ?? 0)

const headers = [
  { title: 'Category', key: 'category_id' },
  { title: 'question', key: 'question' },
  { title: 'Answer', key: 'answer' },
  { title: 'Created', key: 'created_at' },
  { title: 'Actions', key: 'actions', sortable: false },
]

const updateOptions = (options: any) => {
  page.value = options.page
  sortBy.value = options.sortBy[0]?.key
  orderBy.value = options.sortBy[0]?.order
}

const createQuestion = () => {
  question.value = undefined
  showForm.value = true
}

const updateQuestion = _cat => {
  question.value = _cat
  showForm.value = true
}

const updateQuestionStatus = createConfirmAction((event: boolean) => `Are you sure you want to ${event ? 'Feature' : 'Unfeature'} this question`, (event: boolean, _question) => $api(`/admin/faq/questions/${_question.id}/${event ? 'feature' : 'unfeature'}`, {
  method: 'POST',
}))

const deleteQuestion = createConfirmAction('Are you sure you want to delete this question?', (id: number) => $api(`/admin/faq/questions/${id}`, {
  method: 'DELETE',
}))

watch(itemsPerPage, () => {
  page.value = 1
})
</script>

<template>
  <section>
    <VCard :loading="isFetching">
      <VCardText class="d-flex flex-wrap py-4 gap-4">
        <div class="me-3 d-flex gap-3">
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: 'All' },
            ]"
            style="inline-size: 6.25rem;"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="app-bot-search-filter d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <!--
            <div style="inline-size: 10rem;">
            <AppTextField
            v-model="searchQuery"
            placeholder="Search"
            density="compact"
            />
            </div>
          -->

          <!-- 👉 Export button -->
          <!--
            <VBtn
            variant="tonal"
            color="secondary"
            prepend-icon="tabler-screen-share"
            >
            Export
            </VBtn>
          -->
          <VBtn
            prepend-icon="tabler-plus"
            @click="createQuestion"
          >
            Create Question
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <!-- SECTION datatable -->
      <!--      v-model="selectedBots.selected" -->
      <VDataTableServer
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        v-model:sort-by="sort"
        :items="categoriesData"
        :headers="headers"
        :items-length="total"
        show-select
        return-object
        :loading="isFetching"
        :value-comparator="(question1, question2) => question1.id === question2.id"
        @update:options="updateOptions"
      >
        <template #item.category_id="{ item }">
          <VIcon
            :icon="item.category.icon"
            class="me-2"
          />{{ fallbackByFirst(item.category.name) }}
        </template>
        <template #item.question="{ item }">
          {{ fallbackByFirst(item.question) }}
        </template>
        <template #item.answer="{ item }">
          {{ fallbackByFirst(item.answer) }}
        </template>

        <template #item.created_at="{ item }">
          {{ moment(item.created_at).format('YYYY-MM-DD') }}
        </template>
        <!-- User -->

        <!-- Actions -->
        <template #item.actions="{ item }">
          <div class="d-flex items-center">
            <VTooltip :text="item.featured ? `Unfeature Question` : 'Feature Question'">
              <template #activator="{ props }">
                <VSwitch
                  inline
                  v-bind="props"
                  density="compact"
                  color="success"
                  :model-value="!!item.featured"
                  base-color="error"
                  class="ms-2"
                  @update:model-value="($event) => updateQuestionStatus($event, item)"
                />
              </template>
            </VTooltip>
            <VTooltip text="Update Question">
              <template #activator="{ props }">
                <IconBtn
                  v-bind="props"
                  @click="updateQuestion(item)"
                >
                  <VIcon icon="tabler-edit" />
                </IconBtn>
              </template>
            </VTooltip>
            <VTooltip text="Delete Question">
              <template #activator="{ props }">
                <IconBtn
                  v-bind="props"
                  color="error"
                  @click="deleteQuestion(item.id)"
                >
                  <VIcon icon="tabler-trash" />
                </IconBtn>
              </template>
            </VTooltip>
          </div>
        </template>

        <!-- pagination -->
        <template #bottom>
          <VDivider />
          <div class="d-flex align-center justify-sm-space-between justify-center flex-wrap gap-3 pa-5 pt-3">
            <p class="text-sm text-disabled mb-0">
              {{ paginationMeta({ page, itemsPerPage }, total) }}
            </p>

            <VPagination
              v-model="page"
              :length="Math.ceil(total / itemsPerPage)"
              :total-visible="$vuetify.display.xs ? 1 : Math.ceil(total / itemsPerPage)"
            >
              <template #prev="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Previous
                </VBtn>
              </template>

              <template #next="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Next
                </VBtn>
              </template>
            </VPagination>
          </div>
        </template>
      </VDataTableServer>
      <!-- SECTION -->
    </VCard>
  </section>
  <QuestionFormDialog
    v-model="showForm"
    :question="question"
    @update="fetchQuestions"
  />
</template>

<style lang="scss">
.text-error .v-switch__thumb {
  background-color: #EA5455 !important;
}

.v-switch__track {
  background-color: white !important;
}
.v-switch {
  display: flex;
  justify-content: center;
}
</style>
