<script setup lang="ts">
import moment from 'moment/moment'
import { confirmedActionsCreator } from '@/utils/admin/actions'
import CategoryFormDialog from '@/views/admin/faq/categories/CategoryFormDialog.vue'
import { paginationMeta } from '@api/utils/paginationMeta'
import { fallbackByFirst } from '@/utils/helpers'

definePage({
  meta: {
    auth: true,
    guard: 'admin',
    layout: 'admin',
  },
})

const sort = ref([{ key: 'created_at', order: 'desc' }])
const page = ref(1)
const itemsPerPage = ref(50)
const showForm = ref(false)
const category = ref()

const sortBy = ref()
const orderBy = ref()

const { data: responseData, execute: fetchCategories, isFetching } = await useApi(createUrl('/admin/faq/categories', {
  query: {
    per_page: itemsPerPage,
    page,
    sortBy,
    orderBy,
  },
}))

const createConfirmAction = confirmedActionsCreator(fetchCategories)
const categoriesData = computed(() => responseData.value?.data)
const total = computed(() => responseData.value?.total ?? 0)

const headers = [
  { title: 'Name', key: 'name' },
  { title: 'Description', key: 'description' },
  { title: 'Icon', key: 'icon' },
  { title: 'Created', key: 'created_at' },
  { title: 'Actions', key: 'actions', sortable: false },
]

const updateOptions = (options: any) => {
  page.value = options.page
  sortBy.value = options.sortBy[0]?.key
  orderBy.value = options.sortBy[0]?.order
}

const createCategory = () => {
  category.value = undefined
  showForm.value = true
}

const updateCategory = _cat => {
  category.value = _cat
  showForm.value = true
}

const deleteCategory = createConfirmAction('Are you sure you want to delete this category?', (id: number) => $api(`/admin/faq/categories/${id}`, {
  method: 'DELETE',
}))
</script>

<template>
  <section>
    <VCard :loading="isFetching">
      <VCardText class="d-flex flex-wrap py-4 gap-4">
        <div class="me-3 d-flex gap-3">
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: 'All' },
            ]"
            style="inline-size: 6.25rem;"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="app-bot-search-filter d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <!--
            <div style="inline-size: 10rem;">
            <AppTextField
            v-model="searchQuery"
            placeholder="Search"
            density="compact"
            />
            </div>
          -->

          <!-- 👉 Export button -->
          <!--
            <VBtn
            variant="tonal"
            color="secondary"
            prepend-icon="tabler-screen-share"
            >
            Export
            </VBtn>
          -->
          <VBtn
            prepend-icon="tabler-plus"
            @click="createCategory"
          >
            Create Category
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <!-- SECTION datatable -->
      <!--      v-model="selectedBots.selected" -->
      <VDataTableServer
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        v-model:sort-by="sort"
        :items="categoriesData"
        :headers="headers"
        :items-length="total"
        show-select
        return-object
        :loading="isFetching"
        :value-comparator="(category1, category2) => category1.id === category2.id"
        @update:options="updateOptions"
      >
        <template #item.name="{ item }">
          {{ fallbackByFirst(item.name) }}
        </template>
        <template #item.description="{ item }">
          {{ fallbackByFirst(item.description) }}
        </template>
        <template #item.icon="{ item }">
          <VIcon :icon="item.icon" />
        </template>
        <template #item.created_at="{ item }">
          {{ moment(item.created_at).format('YYYY-MM-DD') }}
        </template>
        <!-- User -->

        <!-- Actions -->
        <template #item.actions="{ item }">
          <div class="d-flex items-center">
            <VTooltip text="Update Category">
              <template #activator="{ props }">
                <IconBtn
                  v-bind="props"
                  @click="updateCategory(item)"
                >
                  <VIcon icon="tabler-edit" />
                </IconBtn>
              </template>
            </VTooltip>
            <VTooltip text="Delete Category">
              <template #activator="{ props }">
                <IconBtn
                  v-bind="props"
                  color="error"
                  @click="deleteCategory(item.id)"
                >
                  <VIcon icon="tabler-trash" />
                </IconBtn>
              </template>
            </VTooltip>
          </div>
        </template>

        <!-- pagination -->
        <template #bottom>
          <VDivider />
          <div class="d-flex align-center justify-sm-space-between justify-center flex-wrap gap-3 pa-5 pt-3">
            <p class="text-sm text-disabled mb-0">
              {{ paginationMeta({ page, itemsPerPage }, total) }}
            </p>

            <VPagination
              v-model="page"
              :length="Math.ceil(total / itemsPerPage)"
              :total-visible="$vuetify.display.xs ? 1 : Math.ceil(total / itemsPerPage)"
            >
              <template #prev="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Previous
                </VBtn>
              </template>

              <template #next="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Next
                </VBtn>
              </template>
            </VPagination>
          </div>
        </template>
      </VDataTableServer>
      <!-- SECTION -->
    </VCard>
  </section>
  <CategoryFormDialog
    v-model="showForm"
    :category="category"
    @update="fetchCategories"
  />
</template>

<style scoped lang="scss">

</style>
