import type { App } from 'vue'
import { createI18n } from 'vue-i18n'
import { cookieRef } from '@layouts/stores/config'
import { themeConfig } from '@themeConfig'

const messages = Object.fromEntries(
  Object.entries(
    import.meta.glob<{ default: any }>('./locales/*.json', { eager: true }))
    .map(([key, value]) => [key.slice(10, -5), value.default]),
)

let _i18n: any = null
export const loadLocaleMessages = async (locale: string) => {
  const _messages = await $api(`${locale}.json`)

  _i18n.global.setLocaleMessage(locale, _messages)

  return nextTick()
}

export const checkForLocale = async (locale: string) => {
  void loadLocaleMessages(locale)
}
export function setI18nLanguage(locale: string) {
  if (_i18n.mode === 'legacy')
    _i18n.global.locale = locale
  else
    _i18n.global.locale.value = locale

  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector('html')?.setAttribute('lang', locale)
}

export const setLocale = async (locale: string) => {
  if (!_i18n.global.availableLocales.includes(locale))
    await loadLocaleMessages(locale)

  setI18nLanguage(locale)
}

export const getI18n = () => {
  if (_i18n === null) {
    _i18n = createI18n({
      legacy: false,
      locale: cookieRef('language', themeConfig.app.i18n.defaultLocale).value,
      fallbackLocale: 'en',
      messages,
    })
  }

  return _i18n
}

export default function (app: App) {
  app.use(getI18n())
}
